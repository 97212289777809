.App {
  text-align: center;
}
.navIcon {
  margin-left: 100px;
}
.banner-background{
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
  url('https://images.unsplash.com/photo-1473968512647-3e447244af8f?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&dl=jason-blackeye-XYrjl3j7smo-unsplash.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 650px;
  margin-top: 0px;
}
.banner-texts{
  padding: 1px;
  color: white;
  font-family: 'Lato', sans-serif;
}
.welcome-msg{
  font-size: 20px;
  margin-top: 100px;
}
.agency-name{
  margin-top: -20px;
  font-size: 100px;
}
.short-desc{
  margin-top: -80px;
}
.learn-more{
  display: inline-block;
  text-decoration: none;
  color: white;
  padding: 10px 20px;
  background: #00bcd4;
  border-radius: 5px;
  margin-top: 10px;
}
.learn-more:hover{
  background: #00acc1;
}
.text-center{
  text-align: center;
}
.footer {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background: #02545f;
  color: white;
  padding-bottom: 10px;
}
.footer img{
  width: 30px;
}
.footer a{
  text-decoration: none;
  color: white;
  margin: 2px;
  margin-top: 15px;
}
.text-start{
  text-align: start;
}

.login-bg{
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
  url('https://images.unsplash.com/photo-1508444845599-5c89863b1c44?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  margin: 10px;
  height: 205px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.or{
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(3, 78, 112);
  color: white;
  border-radius: 50%;
  padding: 5px;
  width: 50px;
  height: 50px;
  margin: -10px;
}
.link-text{
  text-decoration: none;
  color: black;
}
.icon-image{
  width: 25px;
}
.overflow-scroll{
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 10px;
  margin: 10px;
}
@media screen and (min-width: 992px) {
  .or {
    margin-top: 70px;
  }
}


